import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import {
  HeroContainer,
  ActionContainer,
  DonationList,
  Overview,
} from "@molecules";
import { Container, Text, Button, Icon, Meta } from "@atoms";
import { useAppState } from "@state";
// import  brand  from "@utils/brand";
import useGivingCampaignData from "@staticQueries/lakotaGc/GivingCampaignQuery";
import { getAction } from "@liveQueries";
import {
  createContributionPublic,
  createContributionAnonymous,
} from "@mutations";
import { navigate } from "gatsby";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/LakotaPageContainer"));

const HistorySection = loadable(() => import("@organisms/HistorySection"));

// if (!loaded) {
//   return (
//     <PageContainer>
//       {!loaded && (
//         <div className="flex h-full flex-grow items-center justify-center p-6">
//           <Icon
//             name="loading"
//             className={classNames("animate-spin text-gray h-12 w-12", {})}
//           />
//         </div>
//       )}
//     </PageContainer>
//   );
// }

const FundraiserPage = ({ ...rest }) => {
  const historySection = useRef();
  const [{ contributions }, dispatch] = useAppState(null);
  // const { romero } = brand;
  const [actionState] = useState(null);
  const [editKey, setEditKey] = useState(null);

  useEffect(() => {
    dispatch({ type: "setThemeDark" });
    dispatch({ type: "showNavBg" });
  }, []);

  const {
    //  hero, stream, rsvp, cta,
    p2p,
    history,
    campaign,
    meta,
  } = useGivingCampaignData();
  const { donateFormId, membershipFormId } = campaign;

  const [error, setError] = useState(false);
  const [slug, setSlug] = useState(null);
  const [fundraiser, setFundraiser] = useState(null);
  const [donations, setDonations] = useState([]);
  const [atCallback, setAtCallback] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (slug) {
      let key = window.localStorage.getItem(slug);
      if (!key) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        key = urlParams.get("edit");
      }
      if (key) {
        window.localStorage.setItem(slug, key);
        setEditKey({ [slug]: key });
      }
    }
  }, [slug]);

  useEffect(() => {
    setSlug(window.location.pathname.replace("/", ""));
  }, []);

  useEffect(() => {
    if (contributions?.contributions) {
      contributions.contributions.then(res => {
        const _donations = res.filter(
          c => c.fundraiser && c.fundraiser.slug === slug
        );
        setDonations(_donations);
        setTotal(
          _donations.map(c => parseInt(c.amount, 10)).reduce((a, b) => a + b, 0)
        );
      });
    }
  }, [contributions, slug]);

  useEffect(() => {
    if (slug) {
      getAction(slug)
        .then(action => {
          if (action) {
            setFundraiser(action);
          } else {
            setError(true);
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log(e);
          setError(true);
        });
    }
  }, [slug]);

  const callbacks = f => ({
    preSegue: e => {
      setAtCallback(e, dispatch);
    },
    alterPost: e => {
      e.data.AttributedContactIds = f.vanId;
    },
    alterFormDefinition: e => {
      const addAnonymousField = args => {
        args.form_definition.form_elements.forEach(child => {
          if (child.name === "ContactInformation") {
            const anonymous = {
              name: "anonymous",
              title: "Make my contribution anonymous.",
              type: "checkbox",
              weight: 99, // We want it to appear at the bottom of the fieldset
              queryString: "anonymous",
            };
            child.children.push(anonymous);
          }
        });
        return args;
      };
      addAnonymousField(e);
    },
  });

  useEffect(() => {
    if (atCallback) {
      const {
        // donate
        anonymous,
        Amount: amount,
        FirstName: fName,
      } = atCallback.postVals;
      const contribution = {
        amount: parseInt(parseFloat(amount, 10) * 100, 10),
        fName,
        fundraiser: [(() => fundraiser.id)()],
      };
      if (anonymous) {
        createContributionAnonymous(contribution).then(() => {
          dispatch({ type: "updateContributions" });
        });
      } else {
        createContributionPublic(contribution).then(() => {
          dispatch({ type: "updateContributions" });
        });
      }
    }
  }, [atCallback]);

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.log(`${slug} could not be found`);
      navigate("/404");
    }
  }, [error]);

  return (
    <PageContainer {...rest}>
      {!fundraiser && (
        <div className="flex h-full min-h-1/2-screen flex-grow items-center justify-center p-6">
          <Icon
            name="loading"
            className={classNames("h-12 w-12 animate-spin text-white", {})}
          />
        </div>
      )}
      {fundraiser && (
        <div className="min-h-1/2-screen">
          <HeroContainer
            image={fundraiser?.image}
            headerBg
            editKey={editKey}
            slug={slug}
          >
            <div className="relative h-full w-full space-y-12 md:w-3/5 md:pr-12 lg:w-2/3">
              <Overview
                {...fundraiser}
                description={p2p.description}
                url={`${process.env.GATSBY_SITE_URL}/${slug}`}
                historySection={historySection.current}
              />
            </div>
            <div className="relative flex w-full flex-col md:w-2/5 lg:w-1/3">
              <ActionContainer
                goal={fundraiser?.goal || 0}
                total={total}
                donateFormId={donateFormId}
                membershipFormId={membershipFormId}
                actionState={actionState}
                callbacks={callbacks(fundraiser)}
              />
            </div>
          </HeroContainer>
          <DonationList donations={donations} />
        </div>
      )}
      {/* alternate CTA */}
      <div className="bg-white py-12 md:py-24">
        <Container variant="xs">
          <div className="flex flex-col items-center space-y-8 whitespace-pre-line text-center">
            <Text variant="h3" className="text-red">
              Join Me In Support of Native Voting Rights
            </Text>
            <Text variant="body--large">
              On Oct. 22-23, the Standing Rock Sioux Tribe and the Lakota
              People&apos;s Law Project will host Rezball3, a Lakota Country
              basketball tournament that will also serve as a massive Native
              voter registration drive. You&apos;re invited to share in this
              first-of-its-kind event and show your support. Click the link
              below to RSVP, attend the livestream, and/or watch the recordings
              after the event.
            </Text>
            <Button to="/">I&apos;m In</Button>
          </div>
        </Container>
      </div>

      {/* history */}
      <div ref={historySection}>
        <HistorySection {...history} color="gray" />
      </div>

      {/* mission */}
      <div className="bg-cream py-12 md:py-24">
        <Container variant="xs">
          <div className="flex flex-col items-center space-y-8 text-center">
            <Icon name="iconLakota" className="h-16 w-16" />
            <Text
              variant="div"
              richText={false}
              className="font-serif text-xl font-bold leading-tight md:text-2xl lg:px-10 lg:px-10 lg:text-h4"
            >
              {history.mission}
            </Text>
            <Button to="https://lakotalaw.org/about">Learn More</Button>
          </div>
        </Container>
      </div>
      {meta && <Meta {...meta} />}
    </PageContainer>
  );
};

FundraiserPage.defaultProps = {};

export default FundraiserPage;
